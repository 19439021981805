import { GetRecaptchaActions, GetRecaptchaState} from "../interfaces/types";
import {getRecaptchaTypes} from "../constants/actionTypes";
import {RootState} from "./index";

const initialState: GetRecaptchaState = {
    pending: false,
    error: null,
    
};

export default (state = initialState, action: GetRecaptchaActions) => {
    

    switch (action.type) {
        case getRecaptchaTypes.FETCH_GET_RECAPTCHA_REQUEST:
            return {
                ...state,
                pending: true
            };
        case getRecaptchaTypes.FETCH_GET_RECAPTCHA_SUCCESS:
            return {
                ...state,
                pending: false,
                ...action.payload,
                data: action.payload,
                error: null,
            };
        case getRecaptchaTypes.FETCH_GET_RECAPTCHA_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.payload.error
            };
        default:
            return state;
    }
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getRecaptchaDetails = (state: RootState) => state.getRecaptcha;