import axios from "axios";
import { BASE_URL} from "../constants/actionTypes";
import StorageService from "./Storage.service";
const token = StorageService.getCookies("accessToken");




//get UploadImage

export const getUserLogo = (payload: any): any => {
    // const employerId = StorageService.getCookies("employerId");
   const employerId = StorageService.getCookies('employerId');
    try {
      return axios({
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
         
        },
        url: `${BASE_URL}/searchemployer/${employerId}/getLogo`,
        responseType:'blob'
      });
    } catch (error) {
      throw error;
    }
  };
  