export const BASE_URL = "https://qleservices.com/qleaca/api";

export const COMMON_CONFIG = "COMMON_CONFIG";

export const PHONE = /^[0-9\- ]{10}$/;

//export const PHONECOUNTRYCODE = /((00)?(\+)?\d{1,3})[-\s]+(\d{1,3})[-\s]+(\d{4,10})/
//export const PHONECOUNTRYCODE = /^\+(?:[0-9] ?){6,14}[0-9]$/;
export const PHONECOUNTRYCODE = /^(\+\d{1,2}\s?)1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/

export enum loginTypes {
    FETCH_LOGIN_REQUEST = "FETCH_LOGIN_REQUEST",
    FETCH_LOGIN_SUCCESS = "FETCH_LOGIN_SUCCESS",
    FETCH_LOGIN_FAILURE = "FETCH_LOGIN_FAILURE",
    LOGGED_INFO = "LOGGED_INFO",
  }

  export enum otpTypes {
    FETCH_OTP_REQUEST = "FETCH_OTP_REQUEST",
    FETCH_OTP_SUCCESS = "FETCH_OTP_SUCCESS",
    FETCH_OTP_FAILURE = "FETCH_OTP_FAILURE",
    OTP_INFO = "OTP_INFO",
  }
  
  export enum resendOTPTypes {
    FETCH_RESEND_OTP_REQUEST = "FETCH_RESEND_OTP_REQUEST",
    FETCH_RESEND_OTP_SUCCESS = "FETCH_RESEND_OTP_SUCCESS",
    FETCH_RESEND_OTP_FAILURE = "FETCH_RESEND_OTP_FAILURE",
    RESEND_OTP_INFO = "RESEND_OTP_INFO",
  }

  export enum homeTypes {
    FETCH_HOME_REQUEST = "FETCH_HOME_REQUEST",
    FETCH_HOME_SUCCESS = "FETCH_HOME_SUCCESS",
    FETCH_HOME_FAILURE = "FETCH_HOME_FAILURE",
    HOME_INFO = "HOME_INFO",
  }

  export enum faqTypes {
    FETCH_FAQ_REQUEST = "FETCH_FAQ_REQUEST",
    FETCH_FAQ_SUCCESS = "FETCH_FAQ_SUCCESS",
    FETCH_FAQ_FAILURE = "FETCH_FAQ_FAILURE",
    FAQ_INFO = "FAQ_INFO",
  }

  export enum step1Types {
    FETCH_STEP1_REQUEST = "FETCH_STEP1_REQUEST",
    FETCH_STEP1_SUCCESS = "FETCH_STEP1_SUCCESS",
    FETCH_STEP1_FAILURE = "FETCH_STEP1_FAILURE",
    STEP1_INFO = "STEP1_INFO",
  }

  export enum getStep1Types {
    FETCH_GETSTEP1_REQUEST = "FETCH_GETSTEP1_REQUEST",
    FETCH_GETSTEP1_SUCCESS = "FETCH_GETSTEP1_SUCCESS",
    FETCH_GETSTEP1_FAILURE = "FETCH_GETSTEP1_FAILURE",
    GETSTEP1_INFO = "GETSTEP1_INFO",
  }

  export enum editStep1Types {
    FETCH_EDITSTEP1_REQUEST = "FETCH_EDISTEP1_REQUEST",
    FETCH_EDITSTEP1_SUCCESS = "FETCH_EDISTEP1_SUCCESS",
    FETCH_EDITSTEP1_FAILURE = "FETCH_EDITSTEP1_FAILURE",
    EDITSTEP1_INFO = "EDITSTEP1_INFO",
  }

  export enum eventsTypes {
    FETCH_EVENTS_REQUEST = "FETCH_EVENTS_REQUEST",
    FETCH_EVENTS_SUCCESS = "FETCH_EVENTS_SUCCESS",
    FETCH_EVENTS_FAILURE = "FETCH_EVENTS_FAILURE",
    EVENTS_INFO = "EVENTS_INFO",
  }

  export enum employerTypes {
    FETCH_EMPLOYER_REQUEST = "FETCH_EMPLOYER_REQUEST",
    FETCH_EMPLOYER_SUCCESS = "FETCH_EMPLOYER_SUCCESS",
    FETCH_EMPLOYER_FAILURE = "FETCH_EMPLOYER_FAILURE",
    EMPLOYER_INFO = "EMPLOYER_INFO",
  }

  export enum reLoginTypes {
    FETCH_RELOGIN_REQUEST = "FETCH_RELOGIN_REQUEST",
    FETCH_RELOGIN_SUCCESS = "FETCH_RELOGIN_SUCCESS",
    FETCH_RELOGIN_FAILURE = "FETCH_RELOGIN_FAILURE",
    RELOGGED_INFO = "RELOGGED_INFO",
  }

  export enum step2Types {
    FETCH_STEP2_REQUEST = "FETCH_STEP2_REQUEST",
    FETCH_STEP2_SUCCESS = "FETCH_STEP2_SUCCESS",
    FETCH_STEP2_FAILURE = "FETCH_STEP2_FAILURE",
    STEP2_INFO = "STEP2_INFO",
  }

  export enum planTypes {
    FETCH_PLAN_REQUEST = "FETCH_PLAN_REQUEST",
    FETCH_PLAN_SUCCESS = "FETCH_PLAN_SUCCESS",
    FETCH_PLAN_FAILURE = "FETCH_PLAN_FAILURE",
    PLAN_INFO = "PLAN_INFO",
  }

  export enum step3Types {
    FETCH_STEP3_REQUEST = "FETCH_STEP3_REQUEST",
    FETCH_STEP3_SUCCESS = "FETCH_STEP3_SUCCESS",
    FETCH_STEP3_FAILURE = "FETCH_STEP3_FAILURE",
    STEP3_INFO = "STEP3_INFO",
  }
  export enum getStep3Types {
    FETCH_GETSTEP3_REQUEST = "FETCH_GETSTEP3_REQUEST",
    FETCH_GETSTEP3_SUCCESS = "FETCH_GETSTEP3_SUCCESS",
    FETCH_GETSTEP3_FAILURE = "FETCH_GETSTEP3_FAILURE",
    GETSTEP3_INFO = "GETSTEP3_INFO",
  }
  export enum editStep3Types {
    FETCH_EDITSTEP3_REQUEST = "FETCH_EDISTEP3_REQUEST",
    FETCH_EDITSTEP3_SUCCESS = "FETCH_EDISTEP3_SUCCESS",
    FETCH_EDITSTEP3_FAILURE = "FETCH_EDITSTEP3_FAILURE",
    EDITSTEP3_INFO = "EDITSTEP3_INFO",
  }
  export enum viewFileTypes {
    FETCH_VIEWFILE_REQUEST = "FETCH_VIEWFILE_REQUEST",
    FETCH_VIEWFILE_SUCCESS = "FETCH_VIEWFILE_SUCCESS",
    FETCH_VIEWFILE_FAILURE = "FETCH_VIEWFILE_FAILURE",
    VIEWFILE_INFO = "VIEWFILE_INFO",
  }

export enum filesViewTypes {
    FETCH_FILESVIEW_REQUEST = "FETCH_FILESVIEW_REQUEST",
    FETCH_FILESVIEW_SUCCESS = "FETCH_FILESVIEW_SUCCESS",
    FETCH_FILESVIEW_FAILURE = "FETCH_FILESVIEW_FAILURE",
    FILESVIEW_INFO = "FILESVIEW_INFO",
  }

  export enum step2CancelTypes {
    FETCH_STEP2CANCEL_REQUEST = "FETCH_STEP2CANCEL_REQUEST",
    FETCH_STEP2CANCEL_SUCCESS = "FETCH_STEP2CANCEL_SUCCESS",
    FETCH_STEP2CANCEL_FAILURE = "FETCH_STEP2CANCEL_FAILURE",
    STEP2CANCEL_INFO = "STEP2CANCEL_INFO",
  }
  export enum PrivacypolicyTypes {
    FETCH_PRIVACYPOLICY_REQUEST = "FETCH_PRIVACYPOLICY_REQUEST",
    FETCH_PRIVACYPOLICY_SUCCESS = "FETCH_PRIVACYPOLICY_SUCCESS",
    FETCH_PRIVACYPOLICY_FAILURE = "FETCH_PRIVACYPOLICY_FAILURE",
    PRIVACYPOLICY_INFO = "PRIVACYPOLICY_INFO",
  }
  export enum slugTypes {
    FETCH_SLUG_REQUEST = "FETCH_SLUG_REQUEST",
    FETCH_SLUG_SUCCESS = "FETCH_SLUG_SUCCESS",
    FETCH_SLUG_FAILURE = "FETCH_SLUG_FAILURE",
    SLUG_INFO = "SLUG_INFO",
  }

  //GET UPLOAD LOGO


export enum getUploadLogoTypes {
  FETCH_GET_UPLOADLOGO_REQUEST = "FETCH_GET_UPLOADLOGO_REQUEST",
  FETCH_GET_UPLOADLOGO_SUCCESS = "FETCH_GET_UPLOADLOGO_SUCCESS",
  FETCH_GET_UPLOADLOGO_FAILURE = "FETCH_GET_UPLOADLOGO_FAILURE",
  GET_UPLOADLOGO_INFO = "GET_UPLOADLOGO_INFO",
}

//filesdelete
export enum filesDeleteTypes {
  FETCH_DELETEFILES_REQUEST = "FETCH_DELETEFILES_REQUEST",
  FETCH_DELETEFILES_SUCCESS = "FETCH_DELETEFILES_SUCCESS",
  FETCH_DELETEFILESE_FAILURE = "FETCH_DELETEFILES_FAILURE",
  DELETEFILESE_INFO = "DELETEFILES_INFO",
}

// get Recaptcha 

export enum getRecaptchaTypes {
  FETCH_GET_RECAPTCHA_REQUEST = "FETCH_GET_RECAPTCHA_REQUEST",
  FETCH_GET_RECAPTCHA_SUCCESS = "FETCH_GET_RECAPTCHA_SUCCESS",
  FETCH_GET_RECAPTCHA_FAILURE = "FETCH_GET_RECAPTCHA_FAILURE",
  GET_RECAPTCHA_INFO = "GET_RECAPTCHA_INFO",
}