import {
  Button,
  Checkbox,
  Grid,
  TextField,
  Typography,
  FormControl,
  RadioGroup,
  Radio,
  FormLabel,
} from "@mui/material";
import React, { Fragment, useContext, useEffect, useState, useCallback } from "react";
import { Box } from "@mui/system";

import { Link, useNavigate } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { fetchSTEP2CancelRequest } from "../../actions/step2CancelActions";
import { Formik, useFormik, useFormikContext, validateYupSchema } from "formik";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";

import Candidate_form from "../RequestBenefit_Cancel_Candidate";
import { IStep2CancelForm } from "../../interfaces/types";
import "./style.css";
import { getSTEP2CancelEnteredDetails } from "../../reducers/step2CancelReducer";

import { toast } from "react-toastify";
import { getStep1Details } from "../../reducers/getStep1Reducer";
import { fetchGetStep1Request } from "../../actions/getStep1Actions";
import { useParams } from "react-router-dom";
import ReactHtmlParser from 'html-react-parser';

import StorageService from "../../services/Storage.service";
import CurrencyInput from '../CurrencyInput';

const steps = [
  "Start My QLE",
  "Request Benefit Changes",
  "Upload Documentation",
];

const RequestBenefitChangesCancel = (props:any,{ }: any) => {

  let employerNameCoki = StorageService.getCookies("employerUrl");
  const dispatch = useDispatch();
  let params = useParams();
  let childArray: any = [];
  let dpChildArray: any = [];
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});
  const completedSteps = () => {
    return Object.keys(completed).length;
  };
  const totalSteps = () => {
    return steps.length;
  };

  const [cancelMedical, setCancelMedical] = React.useState(false);
  const handleMedicalBoxChange = (e: any) => {
    let valStatus = e.target.checked;
    if (e.target.checked == true) {
      setCancelMedical(true);
      props.set(true)
    } else {
      setCancelMedical(false);
    }
  };

  const [cancelDental, setCancelDental] = React.useState(false);
  const handleDentalBoxChange = (e: any) => {
    let valStatus = e.target.checked;
    if (e.target.checked == true) {
      props.set(true)
      setCancelDental(true);
    } else {
      setCancelDental(false);
    }
  };

  const [cancelVision, setCancelVision] = React.useState(false);
  const handleVisionBoxChange = (e: any) => {
    let valStatus = e.target.checked;
    if (e.target.checked == true) {
      props.set(true)
      setCancelVision(true);
    } else {
      setCancelVision(false);
    }
  };

  const [cancelHealthFsa, setCancelHealthFsa] = React.useState(false);
  const [changeHealthFsa, setChangeHealthFsa] = React.useState(false);
  // const [changeHealthFsaAmount, setChangeHealthFsaAmount] = React.useState("");
  const [changeHealthFsaAmount, setChangeHealthFsaAmount] = React.useState(0.0);
  const [phCancelHealthFsa, setPHCancelHealthFsa] = React.useState("");

  const [cancelDependentFsa, setCancelDependentFsa] = React.useState(false);
  const [changeDependentFsa, setChangeDependentFsa] = React.useState(false);
  // const [changeDependentFsaAmount, setChangeDependentFsaAmount] = React.useState("");
  const [changeDependentFsaAmount, setChangeDependentFsaAmount] = React.useState(0.0);
  const [phCancelDependentFsa, setPHCancelDependentFsa] = React.useState("");

  const [changeEmployeeHsa, setChangeEmployeeHsa] = React.useState(false);
  //const [changeEmployeeHsaAmount, setChangeEmployeeHsaAmount] = React.useState("");
  const [changeEmployeeHsaAmount, setChangeEmployeeHsaAmount] = React.useState(0.0);
  const [phCancelEmployeeHsa, setPHCancelEmployeeHsa] = React.useState("");

  const handleCancelDependentFsa = (e: any) => {
    let valStatus = e.target.checked;
    if (e.target.checked == true) {
      props.set(true)
      setCancelDependentFsa(true);
      setChangeDependentFsa(false);
      //setChangeDependentFsaAmount("");
      setChangeDependentFsaAmount(0);
    } else {
      setCancelDependentFsa(false);
      setChangeDependentFsa(true);
    }
  };

  const handleChangeDependentFsa = (e: any) => {
    let valStatus = e.target.checked;
    if (e.target.checked == true) {
      props.set(true)
      setChangeDependentFsa(true);
      setCancelDependentFsa(false);
    } else {
      setChangeDependentFsa(false);
      setCancelDependentFsa(true);
    }
  };

  const handleChangeDependentFsaAmt = useCallback((val: any) => {
    setChangeDependentFsaAmount(val);
    if (val) {
      props.set(true)
      setChangeDependentFsa(true);
      setCancelDependentFsa(false);
    } else {
      setChangeDependentFsa(false);
      setCancelDependentFsa(true);
    }
  }, []);

  // const handleChangeDependentFsaAmt = (e: any) => {
  //   if (e.target.value) {
  //     setChangeDependentFsa(true);
  //     setCancelDependentFsa(false);
  //   } else {
  //     setChangeDependentFsa(false);
  //     setCancelDependentFsa(true);
  //   }
  // };

  const handleChangeHealthFsa = (e: any) => {
    let valStatus = e.target.checked;
    if (e.target.checked == true) {
      props.set(true)
      setChangeHealthFsa(true);
      setCancelHealthFsa(false);
    } else {
      setChangeHealthFsa(false);
      setCancelHealthFsa(true);
    }
  };

  const handleCancelHealthFsa = (e: any) => {
    let valStatus = e.target.checked;
    if (e.target.checked == true) {
      props.set(true)
      setCancelHealthFsa(true);
      setChangeHealthFsa(false);
      //setChangeHealthFsaAmount("");
      setChangeHealthFsaAmount(0);
    } else {
      setCancelHealthFsa(false);
      setChangeHealthFsa(true);
    }
  };

  //const [value, setValue] = useState(0);
  const handleChangeHealthFsaAmt = useCallback((val: any) => {
    // eslint-disable-next-line
    //let v = (val / 100);
    setChangeHealthFsaAmount(val);
    if (val) {
      props.set(true)
      setChangeHealthFsa(true);
      setCancelHealthFsa(false);
    } else {
      setChangeHealthFsa(false);
      setCancelHealthFsa(true);
    }
  }, []);


  // const handleChangeHealthFsaAmt = (e: any) => {
  //   if (e.target.value) {
  //     setChangeHealthFsa(true);
  //     setCancelHealthFsa(false);
  //   } else {
  //     setChangeHealthFsa(false);
  //     setCancelHealthFsa(true);
  //   }
  // };

  const handleChangeEmployeeHsa = (e: any) => {
    let valStatus = e.target.checked;
    if (e.target.checked == true) {
      props.set(true)
      setChangeEmployeeHsa(true);
    } else {
      setChangeEmployeeHsa(false);
      setChangeEmployeeHsaAmount(0);
    }
  };

  const handleChangeEmployeeHsaAmt = useCallback((val: any) => {
    setChangeEmployeeHsaAmount(val);
    if (val) {
      props.set(true)
      setChangeEmployeeHsa(true);
    } else {
      setChangeEmployeeHsa(false);
    }
  }, []);

  // const handleChangeEmployeeHsaAmt = (e: any) => {
  //   if (e.target.value) {
  //     setChangeEmployeeHsa(true);
  //   } else {
  //     setChangeEmployeeHsa(false);
  //   }
  // };

  const [benefitCertBoxStatus, setBenefitCertBoxStatus] = React.useState(false);
  const handleBenefitCertBoxChange = (e: any) => {
    let valStatus = e.target.checked;
    if (e.target.checked == true) {
      props.set(true)
      setBenefitCertBoxStatus(true);
    } else {
      setBenefitCertBoxStatus(false);
    }
  };
  const [infoCertBoxStatus, setInfoCertBoxStatus] = React.useState(false);
  const handleInfoCertBoxChange = (e: any) => {
    let valStatus = e.target.checked;
    if (e.target.checked == true) {
      props.set(true)
      setInfoCertBoxStatus(true);
    } else {
      setInfoCertBoxStatus(false);
    }
  };

  const medicalPlans = [
    { name: "Please Select", value: 0 },
    {
      name: "Optional: Cancel in or Change Health Care Flexible Spending Account (FSA) Annual Contribution/Goal Amount:",
      value: 1
    },
    {
      name: "Employee Only Optional: Cancel in or Change Health Care Flexible Spending Account (FSA) Annual Contribution/Goal Amount:",
      value: 2
    },
    {
      name: `Optional: Cancel in or Change Health Savings Account (HSA) Annual Contribution/Goal Amount:
      (Maximum of $3,650 if you have Employee Only coverage and up to $7,300 for all other coverage levels)`,
      value: 3
    },
  ];

  const [isSubmitted, setSubmitted] = useState(false);

  const [cancelEmployee, setCancelEmployee] = React.useState(false);
  const handleCancelEmployee = (e: any) => {
    let valStatus = e.target.checked;
    if (e.target.checked == true) {
      props.set(true)
      setCancelEmployee(true);
    } else {
      setCancelEmployee(false);
    }
  };

  const [cancelSpouse, setCancelSpouse] = React.useState(false);
  const handleCancelSpouse = (e: any) => {
    let valStatus = e.target.checked;
    if (e.target.checked == true) {
      props.set(true)
      setCancelSpouse(true);
    } else {
      setCancelSpouse(false);
    }
  };

  const [cancelChild, setCancelChild] = React.useState(false);
  const handleCancelChild = (e: any) => {
    let valStatus = e.target.checked;
    if (e.target.checked == true) {
      props.set(true)
      setCancelChild(true);
    } else {
      setCancelChild(false);
    }
  };

  const [cancelDP, setCancelDP] = React.useState(false);
  const handleCancelDP = (e: any) => {
    let valStatus = e.target.checked;
    if (e.target.checked == true) {
      props.set(true)
      setCancelDP(true);
    } else {
      setCancelDP(false);
    }
  };

  const [cancelDPChild, setCancelDPChild] = React.useState(false);
  const handleCancelDPChild = (e: any) => {
    let valStatus = e.target.checked;
    if (e.target.checked == true) {
      props.set(true)
      setCancelDPChild(true);
    } else {
      setCancelDPChild(false);
    }
  };

  const handleDPChild = () => {
    let dpArr = [];
    if (step1Res.benefitDetails.domesticPartnersChild && step1Res.benefitDetails.domesticPartnersChild.length > 0) {
      for (let i = 0; i <= step1Res.benefitDetails.domesticPartnersChild.length - 1; i++)
        dpArr.push(1);
        // props.set(true)
    }
    if (dpArr && dpArr.length == 0) {
      dpArr.push(1);
      // props.set(true)
    }
    return dpArr;
  };

  const handleChild = () => {
    let cArr = [];
    if (step1Res.benefitDetails.child && step1Res.benefitDetails.child.length > 0) {
      for (let i = 0; i <= step1Res.benefitDetails.child.length - 1; i++)
        cArr.push(1);
        // props.set(true)
    }
    if (cArr && cArr.length == 0) {
      cArr.push(1);
      // props.set(true)
    }
    return cArr;
  };

  const [formData, setFormData] = useState<any>([]);

  const validationSchema = yup.object({
    //comments: yup.string().required("Field is required"),
  });

  const [initialValues, setInitialValues] = useState<IStep2CancelForm>({
    eventId: 0,
    cancelMedical: false,
    cancelHealthAnnualFsa: "",
    cancelCoverHealthAnnualHsa: "",
    cancelEmployeeHsa: "",
    cancelDental: false,
    cancelVision: false,
    cancelDependentFsa: false,
    changeDependentFsa: "",
    cancelHealthFsa: false,
    changeHealthFsa: "",
    changeEmployeeHsa: "",
    cancelDependentAnnualFsa: "",
    cancelEmployee: false,
    cancelSpouse: false,
    cancelDomesticPartner: false,
    employee: "",
    spouse: "",
    domesticPartner: "",
    cancelChild: false,
    child: [],
    cancelSpouseChild: false,
    cancelDomesticPartnerChild: false,
    domesticPartnersChild: [],
    comments: "",
    commenterName: "",
  });

  const step1Res = useSelector(getStep1Details);

  useEffect(() => {
    getStep1ByEventId();
  }, []);

  const getStep1ByEventId = () => {
    let eventToken = StorageService.getCookies("eventToken");
    let token: any = params.token ? params.token : eventToken;
    if (token && token !== "0")
      dispatch(
        fetchGetStep1Request({ token })
      );
  };

  let dependentSet: any = [];

  useEffect(() => {
    let eventToken = StorageService.getCookies("eventToken");
    let token: any = params.token ? params.token : eventToken;
    if (token) {

      if (step1Res && step1Res['eventId'] > 0 && step1Res.phCancelDependentFsa) {
        let str = step1Res.phCancelDependentFsa.substring(0, step1Res.phCancelDependentFsa.indexOf("."))
        let match: any = str.replace(/[^0-9]/g, '');
        setPHCancelDependentFsa(match);
      }

      if (step1Res && step1Res['eventId'] > 0 && step1Res.phCancelHealthFsa) {
        let str = step1Res.phCancelHealthFsa.substring(0, step1Res.phCancelHealthFsa.indexOf("."))
        let match: any = str.replace(/[^0-9]/g, '');
        setPHCancelHealthFsa(match);
      }

      if (step1Res && step1Res['eventId'] > 0 && step1Res.phCancelEmpHealthCoverHsa) {
        let str = step1Res.phCancelEmpHealthCoverHsa.substring(0, step1Res.phCancelEmpHealthCoverHsa.indexOf("."))
        let match: any = str.replace(/[^0-9]/g, '');
        setPHCancelEmployeeHsa(match);
      }

      if (step1Res && step1Res['eventId'] > 0 && (step1Res['step'] == 2 || step1Res['step'] == 3)) {

        setCancelMedical(step1Res.benefitDetails.cancelMedical);

        if (step1Res.benefitDetails.cancelDependentFsa) {
          setCancelDependentFsa(step1Res.benefitDetails.cancelDependentFsa);
        }
        if (parseInt(step1Res.benefitDetails.changeDependentFsa)) {
          setChangeDependentFsa(true);
          //setChangeDependentFsaAmount(step1Res.benefitDetails.changeDependentFsa);
          setChangeDependentFsaAmount(parseInt(step1Res.benefitDetails.changeDependentFsa));
        }

        if (step1Res.benefitDetails.cancelHealthFsa) {
          setCancelHealthFsa(step1Res.benefitDetails.cancelHealthFsa);
        }
        if (parseInt(step1Res.benefitDetails.changeHealthFsa)) {
          setChangeHealthFsa(true);
          //setChangeHealthFsaAmount(step1Res.benefitDetails.changeHealthFsa);
          setChangeHealthFsaAmount(parseInt(step1Res.benefitDetails.changeHealthFsa));
        }

        if (parseInt(step1Res.benefitDetails.changeEmployeeHsa)) {
          setChangeEmployeeHsa(true);
          //setChangeEmployeeHsaAmount(step1Res.benefitDetails.changeEmployeeHsa);
          setChangeEmployeeHsaAmount(parseInt(step1Res.benefitDetails.changeEmployeeHsa));
        }

        setCancelDental(step1Res.benefitDetails.cancelDental);
        setCancelVision(step1Res.benefitDetails.cancelVision);
        setCancelDependentFsa(step1Res.benefitDetails.cancelDependentFsa);

        setCancelEmployee(step1Res.benefitDetails.cancelEmployee);
        setCancelSpouse(step1Res.benefitDetails.cancelSpouse);
        setCancelChild(step1Res.benefitDetails.cancelChild);
        setCancelDP(step1Res.benefitDetails.cancelDomesticPartner);
        setCancelDPChild(step1Res.benefitDetails.cancelDomesticPartnerChild);

        setBenefitCertBoxStatus(true);
        setInfoCertBoxStatus(true);

        if (step1Res.benefitDetails.child && step1Res.benefitDetails.child.length > 0) {
          for (let i = 0; i <= step1Res.benefitDetails.child.length - 1; i++)
            childArray.push(1);
        }
        if (childArray && childArray.length == 0) {
          childArray.push(1);
        }

        if (step1Res.benefitDetails.domesticPartnersChild && step1Res.benefitDetails.domesticPartnersChild.length > 0) {
          for (let i = 0; i <= step1Res.benefitDetails.domesticPartnersChild.length - 1; i++)
            dpChildArray.push(1);
        }
        if (dpChildArray && dpChildArray.length == 0) {
          dpChildArray.push(1);
        }

        setInitialValues({
          eventId: step1Res.eventId,
          cancelMedical: false,
          cancelHealthAnnualFsa: "",
          cancelCoverHealthAnnualHsa: "",
          cancelEmployeeHsa: "",
          cancelDental: false,
          cancelVision: false,
          cancelDependentFsa: false,
          changeDependentFsa: "",
          cancelHealthFsa: false,
          changeHealthFsa: "",
          changeEmployeeHsa: "",
          cancelDependentAnnualFsa: "",
          cancelEmployee: false,
          cancelSpouse: false,
          cancelDomesticPartner: false,
          employee: "",
          spouse: "",
          domesticPartner: "",
          cancelChild: false,
          child: [],
          cancelSpouseChild: false,
          cancelDomesticPartnerChild: false,
          domesticPartnersChild: [],
          comments: step1Res.benefitDetails.comments,
          commenterName: step1Res['commenterName'],
        });
      }
      else {
        setInitialValues({
          eventId: 0,
          cancelMedical: false,
          cancelHealthAnnualFsa: "",
          cancelCoverHealthAnnualHsa: "",
          cancelEmployeeHsa: "",
          cancelDental: false,
          cancelVision: false,
          cancelDependentFsa: false,
          changeDependentFsa: "",
          cancelHealthFsa: false,
          changeHealthFsa: "",
          changeEmployeeHsa: "",
          cancelDependentAnnualFsa: "",
          cancelSpouse: false,
          cancelEmployee: false,
          cancelDomesticPartner: false,
          spouse: "",
          employee: "",
          domesticPartner: "",
          cancelChild: false,
          child: [],
          cancelSpouseChild: false,
          cancelDomesticPartnerChild: false,
          domesticPartnersChild: [],
          comments: "",
          commenterName: "",
        });
      }
    }
  }, [step1Res]);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values: IStep2CancelForm) => {
      setSubmitted(true);
      values.eventId = step1Res && step1Res.eventId ? step1Res.eventId : 0;

      values.cancelMedical = cancelMedical;
      values.cancelDental = cancelDental;
      values.cancelVision = cancelVision;

      values.cancelDependentFsa = cancelDependentFsa;
      //values.changeDependentFsa = changeDependentFsaAmount;
      values.changeDependentFsa = (changeDependentFsaAmount).toString();
      values.cancelHealthFsa = cancelHealthFsa;
      //values.changeHealthFsa = changeHealthFsaAmount;
      //values.changeHealthFsa = (changeHealthFsaAmount/100).toString();
      values.changeHealthFsa = (changeHealthFsaAmount).toString();
      //values.changeEmployeeHsa = changeEmployeeHsaAmount;
      values.changeEmployeeHsa = changeEmployeeHsaAmount.toString();

      values.cancelEmployee = cancelEmployee;
      values.cancelSpouse = cancelSpouse;
      values.cancelChild = cancelChild;
      values.cancelSpouseChild = cancelSpouse && cancelChild ? true : false;
      values.cancelDomesticPartner = cancelDP;
      values.cancelDomesticPartnerChild = cancelDPChild ? true : false;

    
      let employeeData = "";
      let spouseData = "";
      let dpData = "";
      let childData: any = [];
      let domesticPartnerAndChildData: any = [];

      if (formData && formData.length > 0) {
        if (formData.findIndex((x: any) => x.title == "Employee") !== -1) {
          let employeeArr = formData.find((x: any) => x.title == "Employee");
          employeeData = employeeArr.candidateName;
        }
        if (formData.findIndex((x: any) => x.title == "Spouse") !== -1) {
          let spouseArr = formData.find((x: any) => x.title == "Spouse");
          spouseData = spouseArr.candidateName;
        }
        if (formData.findIndex((x: any) => x.title.includes("Spouse's Child(ren)")) !== -1) {
          let childArr = formData.filter((x: any) => x.title.includes("Spouse's Child(ren)"));
          childData = childArr.map((x: any) => x.candidateName);
        }
        if (formData.findIndex((x: any) => x.title == "Domestic Partner") !== -1) {
          let dpArr = formData.find((x: any) => x.title == "Domestic Partner");
          dpData = dpArr.candidateName;
        }
        if (formData.findIndex((x: any) => x.title.includes("Domestic Partner's Child(ren)")) !== -1) {
          let dpArr = formData.filter((x: any) => x.title.includes("Domestic Partner's Child(ren)"));
          domesticPartnerAndChildData = dpArr.map((x: any) => x.candidateName);
        }
      }

      values.employee = employeeData;
      values.spouse = spouseData;
      values.child = childData;
      values.domesticPartnersChild = domesticPartnerAndChildData;
      values.domesticPartner = dpData;


      let err = false;

      if (changeDependentFsa && !changeDependentFsaAmount) err = true;

      // if (
      //   changeDependentFsa &&
      //   (parseInt(changeDependentFsaAmount) > parseInt(step1Res.phCancelDependentFsa)
      //     || parseInt(changeDependentFsaAmount) == 0
      //     || !changeDependentFsaAmount))
      //   err = true;

      if (
        changeDependentFsa &&
        (changeDependentFsaAmount > parseInt(phCancelDependentFsa)
          || changeDependentFsaAmount == 0
          || !changeDependentFsaAmount))
        err = true;

      if (changeHealthFsa && !changeHealthFsaAmount) err = true;

      // if (
      //   changeHealthFsa &&
      //   (parseInt(changeHealthFsaAmount) > parseInt(step1Res.phCancelHealthFsa)
      //     || parseInt(changeHealthFsaAmount) == 0
      //     || !changeHealthFsaAmount))
      //   err = true;

      if (
        changeHealthFsa &&
        (changeHealthFsaAmount > parseInt(phCancelHealthFsa)
          || changeHealthFsaAmount == 0
          || !changeHealthFsaAmount))
        err = true;

      if (changeEmployeeHsa && !changeEmployeeHsaAmount) err = true;

      // if (
      //   changeEmployeeHsa &&
      //   (parseInt(changeEmployeeHsaAmount) > parseInt(step1Res.phCancelEmpHealthCoverHsa)
      //     || parseInt(changeEmployeeHsaAmount) == 0
      //     || !changeEmployeeHsaAmount))
      //   err = true;

      if (
        changeEmployeeHsa &&
        (changeEmployeeHsaAmount > parseInt(phCancelEmployeeHsa)
          || changeEmployeeHsaAmount == 0
          || !changeEmployeeHsaAmount))
        err = true;

      // if (!cancelMedical && !cancelDental && !cancelVision && step1Res.dependentOption != 5)
      //   err = true;

      if (!cancelMedical && !cancelDental && !cancelVision && step1Res.dependentOption != 5
        && (!cancelDependentFsa && !changeDependentFsa)
        && (!cancelHealthFsa && !changeHealthFsa)
        && (!changeEmployeeHsa)
      )
        err = true;

      if (!cancelEmployee && !cancelSpouse && !cancelChild && !cancelDP && !cancelDPChild 
        && step1Res.dependentOption != 5)
        err = true;

      if (step1Res && step1Res.dependentOption == 5 && (!cancelDependentFsa && !changeDependentFsa))
        err = true;

      if (values.cancelSpouse && !values.spouse)
        err = true;

      if (values.cancelChild && values.child.length <= 0)
      err = true;

      if (values.cancelDomesticPartner && !values.domesticPartner)
      err = true;

      if (values.cancelDomesticPartnerChild && values.domesticPartnersChild.length <= 0)
      err = true;

      if (values.child.length > 0) {
        values.child.forEach((element: any) => {
          if (!element)
            err = true;
        });
      }

      if (values.domesticPartnersChild.length > 0) {
        values.domesticPartnersChild.forEach((element: any) => {
          if (!element)
            err = true;
        });
      }

      if (step1Res && step1Res.dependentOption == 1
        && !cancelEmployee && !cancelSpouse && !cancelChild && !cancelDP && !cancelDPChild) {
        err = true;
      }

      if (benefitCertBoxStatus && infoCertBoxStatus && !err) {
       
        dispatch(fetchSTEP2CancelRequest(values));
      }
    }
  });

  const navigate = useNavigate();

  let step2Res: any = useSelector(getSTEP2CancelEnteredDetails);
  useEffect(() => {
    if (step2Res && step2Res.isSuccess == false) {
      toast.error(step2Res.errorMessages[0]);
    }
    else if (step2Res && step2Res.isSuccess) {
      toast.success(`Saved Step 2`);
      step2Res = null;
      setTimeout(() => {
        navigate(`/` + employerNameCoki + "/uploaddocument");
        document.location.reload();
      }, 1500);
    }
  }, [step2Res]);
  const setConfirm = () => {
    props.set(true);
  }
  return (
    <form onSubmit={formik.handleSubmit}>
      <Box>
        <table>
          <tr>
            <th className="text-muted">Name:</th>
            <td>{step1Res && step1Res.name}</td>
          </tr>
          <tr>
            <th className="text-muted">Event Type:</th>
            <td>{step1Res && step1Res.eventType}</td>
          </tr>
          <tr>
            <th className="text-muted">Sub-Event Type:</th>
            <td>{step1Res && step1Res.eventSubType}</td>
          </tr>
          <tr>
            <th className="text-muted" >Confirmation Number:</th>
            <td  >{step1Res && step1Res.confirmationNumber}</td>

          </tr>
        </table>
        <Typography className="ques-styling" variant="h5" sx={{ marginTop: "25px" }}>
          What changes would you like to make?
        </Typography>

        <Typography className="ans-styling">
          Thanks for starting your qualifying life event (QLE). The next step is to let us know what changes you want to make to your benefits as the result of your QLE. Remember, you can only make changes that are related to your QLE.
        </Typography>

        <Typography className="ques-styling" variant="h5" sx={{ marginTop: "20px" }}>
          {" "}
          Based on your Event Type, you can make the following changes to your
          Randstad benefits:
        </Typography>

        {step1Res && (
          <p
            dangerouslySetInnerHTML={{
              __html: step1Res.qleWhatBenefitChangesStep2,
            }}
          ></p>
        )}
        <Typography className="ques-styling" variant="h5" style={{ margin: "16px 0px 0px 0px" }}>
          Who can you disenroll in coverage?
        </Typography>


        <Typography className="ans-styling" id="para">
          {step1Res && (
            <p
              dangerouslySetInnerHTML={{
                __html: step1Res.qleWhoChangedBenefitStep2,
              }}
            ></p>
          )}
        </Typography>

        <Typography className="ans-styling" id="paraLink" >
          {step1Res && (
            <p
              dangerouslySetInnerHTML={{ __html: step1Res.qleDisclaimerStep2 }}
            ></p>
          )}
        </Typography>
        <br />

        <Typography className="ques-styling change" variant="h5">
          What are we changing?
        </Typography>

        {step1Res
          && (step1Res.dependentOption != 5
          ) ? (
          <Grid container className="cancel" style={{ marginTop: "20px" }}>

            <Grid item xs={12} md={2} >
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  id="cancelMedical"
                  label="Cancel Medical"
                  onChange={handleMedicalBoxChange}
                  checked={cancelMedical}
                />
              </FormGroup>
            </Grid>
          </Grid>
        )
          :
          <></>
        }

        {step1Res
          && (step1Res.dependentOption != 5
          ) ? (
          <Grid container className="cancel">
            <Grid item xs={12} md={2}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Cancel Dental"
                  onChange={handleDentalBoxChange}
                  checked={cancelDental}
                />
              </FormGroup>
            </Grid>
          </Grid>
        )
          :
          <></>
        }

        {step1Res
          && (step1Res.dependentOption != 5
          ) ? (
          <Grid container className="cancel">
            <Grid item xs={12} md={2}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Cancel Vision"
                  onChange={handleVisionBoxChange}
                  checked={cancelVision}
                />
              </FormGroup>
            </Grid>
          </Grid>
        )
          :
          <></>
        }

        <Grid container sx={{ margin: "14px 0px" }}>
          <FormControl>
            <FormLabel className="FSA-Annual" id="demo-controlled-radio-buttons-group">Cancel or Change Dependent Care FSA Annual Contribution/Goal Amount</FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
            //value={value}
            //onChange={handleDependentFSABoxChange}
            >
              <FormControlLabel
                value="cancelDependentFsa"
                onChange={handleCancelDependentFsa}
                control={<Radio />}
                label="Cancel Dependent Care FSA Annual Contribution/Goal Amount"
                checked={cancelDependentFsa}
              />
              <Grid container sx={{ margin: "14px 0px" }}>

                <Grid item xs={12} md={8}>
                  <FormControlLabel
                    value="changeDependentFsa"
                    onChange={handleChangeDependentFsa}
                    control={<Radio />}
                    label={"Change Dependent Care FSA Annual Contribution/Goal Amount"
                      +
                      (step1Res &&
                        " (Maximum " + step1Res.phCancelDependentFsa + ")"
                      )
                    }
                    checked={changeDependentFsa}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <FormControl fullWidth sx={{ m: 1, ml: 0 }} className="currency">
                    <InputLabel htmlFor="outlined-adornment-amount"></InputLabel>
                    {/* <OutlinedInput
                      id="outlined-adornment-amount"
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                      label=""
                      name="changeDependentFsaAmount"
                      placeholder={
                        (step1Res &&
                          "Maximum " + step1Res.phCancelDependentFsa
                        )
                      }
                      value={changeDependentFsaAmount}
                      onChange={(e: any) => {
                        setChangeDependentFsaAmount(e.target.value);
                        handleChangeDependentFsaAmt(e);
                      }}

                    /> */}
                    <CurrencyInput
                      className="currencyInput"
                      max={100000000}
                      onValueChange={handleChangeDependentFsaAmt}
                      // style={{ textAlign: 'left' }}
                      value={changeDependentFsaAmount}
                    />
                  </FormControl>
                  <div
                    className={
                      (changeDependentFsa && !changeDependentFsaAmount
                        && isSubmitted)
                        ? "error-span cert "
                        :
                        (changeDependentFsa && step1Res
                          && (
                            changeDependentFsaAmount > parseInt(phCancelDependentFsa)
                            ||
                            changeDependentFsaAmount <= 0
                          )
                          && isSubmitted)
                          ? "error-span cert Dependent"
                          :
                          "error-span"
                    }
                  >
                    {
                      (changeDependentFsa && !changeDependentFsaAmount
                        && isSubmitted)
                        ? "Please enter amount"
                        : (changeDependentFsa && step1Res
                          && (
                            changeDependentFsaAmount > parseInt(phCancelDependentFsa)
                            ||
                            changeDependentFsaAmount <= 0
                          )
                          && isSubmitted)
                          ? "Please enter a value from $1 to " + step1Res.phCancelDependentFsa + "."
                          :
                          ""
                    }

                  </div>
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>

          {/* <div
            className={
              !cancelDependentFsa && step1Res && step1Res.dependentOption == 5 && isSubmitted ? "error-span cert" : "error-span"
            }
          >
            {!cancelDependentFsa && step1Res && step1Res.dependentOption == 5 && isSubmitted ? "Please select FSA option" : ""}
          </div> */}
        </Grid>

        {step1Res
          && (step1Res.dependentOption != 5
          ) ? (
        <Grid container sx={{ margin: "14px 0px" }}>
          <FormControl>
            <FormLabel className="FSA-Annual" id="demo-controlled-radio-buttons-group">Cancel or Change Health Care FSA Annual Contribution/Goal Amount</FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
            //value={value}
            // onChange={(e: any) => {
            //   setCancelHealthAnnualFsa(e.target.value);
            //   formik.handleChange(e);
            // }}
            >
              <FormControlLabel
                value="cancelHealthFsa"
                control={<Radio />}
                onChange={handleCancelHealthFsa}
                label="Cancel Health Care FSA Annual Contribution/Goal Amount"
                checked={cancelHealthFsa}
              />
              <Grid container sx={{ margin: "14px 0px" }}>

                <Grid item xs={12} md={8}>
                  <FormControlLabel
                    value="changeHealthFsa"
                    control={<Radio />}
                    onChange={handleChangeHealthFsa}
                    label={"Change Health Care FSA Annual Contribution/Goal Amount"
                      + (step1Res &&
                        " (Maximum " + step1Res.phCancelHealthFsa + ")"
                      )
                    }
                    checked={changeHealthFsa}
                  />
                </Grid>

                <Grid item xs={12} md={4}>

                  <FormControl fullWidth sx={{ m: 1, ml: 0 }} className="currency">
                    <InputLabel htmlFor="outlined-adornment-amount"></InputLabel>
                    {/* <OutlinedInput
                      id="outlined-adornment-amount"
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                      label=""
                      name="changeHealthFsaAmount"
                      placeholder={
                        (step1Res &&
                          "Maximum " + step1Res.phCancelHealthFsa
                        )
                      }
                      value={changeHealthFsaAmount}
                      onChange={(e: any) => {
                        setChangeHealthFsaAmount(e.target.value);
                        //formik.handleChange(e);
                        handleChangeHealthFsaAmt(e);
                      }}

                    /> */}
                    <CurrencyInput
                      className="currencyInput"
                      max={100000000}
                      onValueChange={handleChangeHealthFsaAmt}
                      // style={{ textAlign: 'left' }}
                      value={changeHealthFsaAmount}
                    />
                  </FormControl>

                  <div
                    className={
                      (changeHealthFsa && !changeHealthFsaAmount
                        && isSubmitted)
                        ? "error-span cert "
                        :
                        (changeHealthFsa && step1Res
                          && (
                            (changeHealthFsaAmount) > parseInt(phCancelHealthFsa)
                            ||
                            changeHealthFsaAmount <= 0
                          )
                          && isSubmitted)
                          ? "error-span cert Dependent"
                          :
                          "error-span"
                    }
                  >
                    {
                      (changeHealthFsa && !changeHealthFsaAmount
                        && isSubmitted)
                        ? "Please enter amount"
                        : (changeHealthFsa && step1Res
                          && (
                            changeHealthFsaAmount > parseInt(phCancelHealthFsa)
                            ||
                            changeHealthFsaAmount <= 0
                          )
                          && isSubmitted)
                          ? "Please enter a value from $1 to " + step1Res.phCancelHealthFsa + "."
                          :
                          ""
                    }

                  </div>

                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>

        </Grid>
          )
          :
          <></>
        }

        {step1Res
          && (step1Res.dependentOption != 5
          ) ? (
          <Grid container sx={{ margin: "14px 0px" }}>
            <Grid className="HSA-Annual">
              <Grid className="HSA-width" item xs={12} md={6}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label={"Change Health Care HSA Annual Contribution/Goal Amount"
                      + (step1Res &&
                        " (Maximum " + step1Res.phCancelEmpHealthCoverHsa + ")"
                      )
                    }
                    onChange={handleChangeEmployeeHsa}
                    checked={changeEmployeeHsa}
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12} md={4}>

                <FormControl fullWidth sx={{ m: 1, ml: 0 }} className="max-amount">
                  <InputLabel htmlFor="outlined-adornment-amount"></InputLabel>
                  {/* <OutlinedInput
                    className="changeEmployeeHsaAmount"
                    id="outlined-adornment-amount"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    label=""
                    name="changeEmployeeHsaAmount"
                    placeholder={
                      (step1Res &&
                        "Maximum " + step1Res.phCancelEmpHealthCoverHsa
                      )
                    }
                    value={changeEmployeeHsaAmount}
                    onChange={(e: any) => {
                      setChangeEmployeeHsaAmount(e.target.value);
                      //formik.handleChange(e);
                      handleChangeEmployeeHsaAmt(e);
                    }}

                  /> */}
                  <CurrencyInput
                    className="currencyInput"
                    max={100000000}
                    onValueChange={handleChangeEmployeeHsaAmt}
                    // style={{ textAlign: 'left' }}
                    value={changeEmployeeHsaAmount}
                  />

                </FormControl>

                <div
                  className={
                    (changeEmployeeHsa && !changeEmployeeHsaAmount
                      && isSubmitted)
                      ? "error-span cert "
                      :
                      (changeEmployeeHsa && step1Res
                        && (
                          changeEmployeeHsaAmount > parseInt(phCancelEmployeeHsa)
                          ||
                          changeEmployeeHsaAmount <= 0
                        )
                        && isSubmitted)
                        ? "error-span cert HSA"
                        :
                        "error-span"
                  }
                >
                  {
                    (changeEmployeeHsa && !changeEmployeeHsaAmount
                      && isSubmitted)
                      ? "Please enter amount"
                      : (changeEmployeeHsa && step1Res
                        && (
                          changeEmployeeHsaAmount > parseInt(phCancelEmployeeHsa)
                          ||
                          changeEmployeeHsaAmount <= 0
                        )
                        && isSubmitted)
                        ? "Please enter a value from $1 to " + step1Res.phCancelEmpHealthCoverHsa + "."
                        :
                        ""
                  }
                </div>
              </Grid>
            </Grid>
          </Grid>
        )
          :
          <></>
        }
        {/* <div
          className={
            !cancelMedical && !cancelDental && !cancelVision && isSubmitted && step1Res && step1Res.dependentOption != 5 ? "error-span cert anyone" : "error-span"
          }
        >
          {!cancelMedical && !cancelDental && !cancelVision && isSubmitted && step1Res && step1Res.dependentOption != 5 ? "Please select from the above options" : ""}
        </div> */}

        <div
          className={
            (step1Res && !cancelMedical && !cancelDental && !cancelVision && step1Res.dependentOption != 5
              && !cancelDependentFsa && !changeDependentFsa
              && !cancelHealthFsa && !changeHealthFsa
              && !changeEmployeeHsa
              && isSubmitted
            )
              ? "error-span cert anyone" 
              :  (step1Res && step1Res.dependentOption == 5
                && !cancelDependentFsa && !changeDependentFsa
                && isSubmitted
              )
              ?
              "error-span cert anyone" 
              :
              "error-span"
          }
        >{
            (step1Res && !cancelMedical && !cancelDental && !cancelVision && step1Res.dependentOption != 5
              && !cancelDependentFsa && !changeDependentFsa
              && !cancelHealthFsa && !changeHealthFsa
              && !changeEmployeeHsa
              && isSubmitted
            ) ? "Please select atleast one of the above options" 
            :  (step1Res && step1Res.dependentOption == 5
              && !cancelDependentFsa && !changeDependentFsa
              && isSubmitted
            )
            ? "Please select atleast one of the above options" 
            : 
            ""}
        </div>

        {step1Res
          && (step1Res.dependentOption == 1
            || step1Res.dependentOption == 4
            || step1Res.dependentOption == 7
            || step1Res.dependentOption == 8
            || step1Res.dependentOption == 6) ? (
          <Typography className="ques-styling select" variant="h6" >
            Select individuals to cancel from coverage
          </Typography>)
          :
          ""
        }

        <Grid container sx={{ margin: "27px 0px" }}>
          {console.log(step1Res && step1Res.dependentOption)}
          {step1Res
            && (step1Res.dependentOption == 1
              || step1Res.dependentOption == 4
            ) ? (
            <Grid item xs={12} md={6} className="spouse" >
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Employee"
                  onChange={handleCancelEmployee}
                  checked={cancelEmployee}
                />
              </FormGroup>
            </Grid>
          )
            :
            <></>
          }
        </Grid>

        <Grid container className="spouse_checkbox">
          {console.log(step1Res && step1Res.dependentOption)}
          {step1Res
            && (step1Res.dependentOption == 1
              || step1Res.dependentOption == 4
              || step1Res.dependentOption == 7) ? (
            <Grid item xs={12} md={6} className="spouse">
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Spouse"
                  onChange={handleCancelSpouse}
                  checked={cancelSpouse}
                />
              </FormGroup>
            </Grid>
          )
            :
            <></>
          }
        </Grid>
        <Grid container sx={{ marginBottom: "-2%" }}>
          {step1Res
            && (step1Res.dependentOption == 1
              || step1Res.dependentOption == 4
              || step1Res.dependentOption == 7) ? (
            <Grid item xs={12} md={6} sx={{ marginLeft: "-5px" }}>
              <Candidate_form title={"Spouse"}
                formData={formData}
                setFormData={setFormData}
                employee={step1Res.benefitDetails.employee}
                child={step1Res.benefitDetails.child}
                dpChild={step1Res.benefitDetails.domesticPartnersChild}
                spouse={step1Res.benefitDetails.spouse}
                domesticPartner={step1Res.benefitDetails.domesticPartner}
                childrenData={[]}
                action={step1Res && (step1Res.benefitDetails.child || step1Res.benefitDetails.domesticPartnersChild) ? "edit" : "add"}
                isSubmitted={isSubmitted}
                enabled={cancelSpouse}
                setCancelChild={setCancelChild}
                setCancelDPChild={setCancelDPChild}
              />
            </Grid>
          )
            :
            <></>
          }
        </Grid>

        <Grid container sx={{ margin: "0px 0px 25px 0px" }} >
          {step1Res && (step1Res.dependentOption == 1
            || step1Res.dependentOption == 2
            || step1Res.dependentOption == 4
            || step1Res.dependentOption == 6
            || step1Res.dependentOption == 7) ? (
            <Grid item xs={12} md={8} className="spouse1" >
              <FormGroup>
                <FormControlLabel
                  className="Child"
                  control={<Checkbox />}
                  label="Dependent Child"
                  onChange={handleCancelChild}
                  checked={cancelChild}
                />
              </FormGroup>
            </Grid>
          )
            :
            <></>
          }
        </Grid>
        <Grid container >
          {step1Res && (step1Res.dependentOption == 1
            || step1Res.dependentOption == 2
            || step1Res.dependentOption == 4
            || step1Res.dependentOption == 6
            || step1Res.dependentOption == 7) ? (
            <Grid item xs={12} md={6}>
              {console.log(cancelChild)}
              {console.log("here")}
              {console.log(childArray)}
              <Candidate_form title={"Spouse's Child(ren)"}
                formData={formData}
                setFormData={setFormData}
                employee={step1Res.benefitDetails.employee}
                child={step1Res.benefitDetails.child}
                dpChild={step1Res.benefitDetails.domesticPartnersChild}
                spouse={step1Res.benefitDetails.spouse}
                domesticPartner={step1Res.benefitDetails.domesticPartner}
                childrenData={handleChild}
                action={step1Res && (step1Res.benefitDetails.child || step1Res.benefitDetails.domesticPartnersChild) ? "edit" : "add"}
                isSubmitted={isSubmitted}
                enabled={cancelChild}
                setCancelChild={setCancelChild}
                setCancelDPChild={setCancelDPChild}
              />
            </Grid>
          )
            :
            <></>
          }
        </Grid>

        <Grid container sx={{ margin: "27px 0px", marginTop: "-30px" }}>
          {step1Res && (step1Res.dependentOption == 1 || step1Res.dependentOption == 4 || step1Res.dependentOption == 8) ? (
            <Grid item xs={12} md={8} className="spouse">
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Domestic Partner"
                  onChange={handleCancelDP}
                  checked={cancelDP}
                />
              </FormGroup>
            </Grid>
          )
            :
            <></>
          }
        </Grid>
        <Grid container >
          {step1Res && (step1Res.dependentOption == 1 || step1Res.dependentOption == 4 || step1Res.dependentOption == 8) ? (
            <Grid item xs={12} md={6} sx={{ marginLeft: "-5px" }}>
              <Candidate_form title={"Domestic Partner"}
                formData={formData}
                setFormData={setFormData}
                employee={step1Res.benefitDetails.employee}
                child={step1Res.benefitDetails.child}
                dpChild={step1Res.benefitDetails.domesticPartnersChild}
                spouse={step1Res.benefitDetails.spouse}
                domesticPartner={step1Res.benefitDetails.domesticPartner}
                childrenData={[1]}
                action={step1Res && (step1Res.benefitDetails.child || step1Res.benefitDetails.domesticPartnersChild) ? "edit" : "add"}
                isSubmitted={isSubmitted}
                enabled={cancelDP}
                setCancelChild={setCancelChild}
                setCancelDPChild={setCancelDPChild}
              />
            </Grid>
          )
            :
            <></>
          }
        </Grid>

        <Grid container className="Domestic ">
          {step1Res && (step1Res.dependentOption == 1 || step1Res.dependentOption == 8) ? (
            <Grid item xs={12} md={8} className="spouse2">
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Domestic Partner's Child"
                  onChange={handleCancelDPChild}
                  checked={cancelDPChild}
                />
              </FormGroup>
            </Grid>
          )
            :
            <></>
          }
        </Grid>
        <Grid container sx={{ margin: "-8px 0px 40px 0px" }} className="Domestic Partner's">
          {console.log("arrrrr")}
          {console.log(dpChildArray)}
          {step1Res && (step1Res.dependentOption == 1 || step1Res.dependentOption == 8) ? (
            <Grid item xs={12} md={6} sx={{ marginLeft: "-5px" }}>
              <Candidate_form title={"Domestic Partner's Child(ren)"}
                formData={formData}
                setFormData={setFormData}
                employee={step1Res.benefitDetails.employee}
                child={step1Res.benefitDetails.child}
                dpChild={step1Res.benefitDetails.domesticPartnersChild}
                spouse={step1Res.benefitDetails.spouse}
                domesticPartner={step1Res.benefitDetails.domesticPartner}
                childrenData={handleDPChild}
                action={step1Res && (step1Res.benefitDetails.child || step1Res.benefitDetails.domesticPartnersChild) ? "edit" : "add"}
                isSubmitted={isSubmitted}
                enabled={cancelDPChild}
                setCancelChild={setCancelChild}
                setCancelDPChild={setCancelDPChild}
              />
            </Grid>
          )
            :
            <></>
          }
        </Grid>
        {isSubmitted && !cancelEmployee && !cancelSpouse && !cancelChild && !cancelDP && !cancelDPChild && step1Res.dependentOption != 5 ? (
          <Typography className="error-span cert individuals" variant="h6" >
            Please select atleast one of the above individuals.
          </Typography>)
          :
          ""
        }

        <Typography className="ques-styling Additional" variant="h6" style={{ marginBottom: "10px" }} >
          Additional Comments
        </Typography>

        <Grid>
          <TextField
            id="outlined-basic"
            label="  comments here"
            variant="outlined"
            name="comments"
            fullWidth
            value={formik.values.comments}
            onChange={e => { formik.handleChange(e) ; setConfirm()}}
            error={formik.touched.comments && Boolean(formik.errors.comments)}
            helperText={formik.touched.comments && formik.errors.comments}
          />
        </Grid>

        <Typography className="ques-styling Benefit" variant="h5" >
          Benefit Change Confirmation
        </Typography>

        <Grid container >
          <Grid>
            <FormGroup>
              <FormControlLabel
                label="I confirm that the requested benefit changes above are the only benefit changes that I'm requesting, and I authorize VCG to make these changes on my behalf. I understand that VCG will only make the benefit changes that I am eligible for based on my qualifying life event and the documentation I submit. Further, I understand that by authorizing and making changes to my benefits, there may be a change to my per paycheck benefit deductions, and I authorize Randstad to deduct from my earnings the amount for the coverage selected."
                value="end"
                id="qleCertBox"
                name="qleCertBox"
                control={<Checkbox className="" style={{marginTop:"-10px"}} />}
                labelPlacement="end"
                sx={{ fontSize: ".875rem !important",alignItems:"flex-start" }}
                onChange={handleBenefitCertBoxChange}
                checked={benefitCertBoxStatus}
              />
            </FormGroup>
          </Grid>
          <div
            className={
              !benefitCertBoxStatus && isSubmitted
                ? "error-span cert check"
                : "error-span"
            }
          >
            {!benefitCertBoxStatus && isSubmitted
              ? "Please accept information provide"
              : ""}
          </div>
        </Grid>

        <Grid container sx={{ marginTop: "20px" }}>
          <Grid>
            <FormGroup>
              <FormControlLabel
                value="end"
                id="qleCertBox"
                name="qleCertBox"
                control={<Checkbox className="" style={{marginTop:"-10px"}} />}
                label="I certify that all of the information provided is true and correct. I further understand that providing any fraudulent statements, falsification, or material omission of information may subject me to discipline, up to and including termination of employment."
                labelPlacement="end"
                sx={{ fontSize: ".875rem !important",alignItems:"flex-start" }}
                onChange={handleInfoCertBoxChange}
                checked={infoCertBoxStatus}
              />
            </FormGroup>
          </Grid>
          <div
            className={
              !infoCertBoxStatus && isSubmitted
                ? "error-span cert check"
                : "error-span"
            }
          >
            {!infoCertBoxStatus && isSubmitted
              ? "Please accept information provide"
              : ""}
          </div>
        </Grid>
        <Grid container style={{ marginLeft: "35px", marginTop: "37px" }}>
          <Grid item xs={12} md={3} >
            <TextField
              className="commenterName"
              id="outlined-basic"
              label="Please type your first and last name here"
              variant="outlined"
              name="commenterName"
              // fullWidth
              value={formik.values.commenterName}
              onChange={e => { formik.handleChange(e) ; setConfirm()}}
              error={formik.touched.commenterName && Boolean(formik.errors.commenterName)}
              helperText={formik.touched.commenterName && formik.errors.commenterName}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          sx={{
            backgroundColor: "red",
            "&:hover": {
              backgroundColor: "orange",
            },
            marginTop: "15px"
          }}
          variant="contained"
          className="flex-end btn-bg-red"
        >
          {completedSteps() === totalSteps() - 1
            ? "Submit"
            : "Save and continue"}
        </Button>
      </Box>
    </form>
  );
};

export default RequestBenefitChangesCancel;
