import React, { useEffect, useState, useRef } from "react";
import { Box, flexbox } from "@mui/system";
import Typography from "@mui/material/Typography";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, InputLabel, TableCell, TextField } from "@mui/material";
import DeleteForeverSharpIcon from "@mui/icons-material/DeleteForeverSharp";
import { Link, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import "./style.css";
import * as yup from "yup";
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from "react-redux";
import { fetchSTEP3Request } from "../../actions/step3Actions";
import { Istep3Form } from "../../interfaces/types";
import { getSTEP1EnteredDetails } from "../../reducers/step1Reducer";
import { fetchGetStep3Request } from "../../actions/getStep3Actions";
import { useParams } from "react-router-dom";
import { getStep3Details } from "../../reducers/getstep3Reducer";
import { fetchEditStep3Request } from "../../actions/editStep3Actions";
import { getEditStep3Details } from "../../reducers/editStep3Reducer";
import { toast } from "react-toastify";
import { getSTEP3EnteredDetails } from "../../reducers/step3Reducer";
import FileViewer from "react-file-viewer";
import FilePreview from "react-file-preview-latest";
import { UploadFile, PictureAsPdf, Image, Preview } from "@mui/icons-material";
import { string } from "yup/lib/locale";
import { getStep1Details } from "../../reducers/getStep1Reducer";
import { fetchGetStep1Request } from "../../actions/getStep1Actions";
import ArticleIcon from "@mui/icons-material/Article";
import { useFormik } from "formik";
import ReactHtmlParser from "html-react-parser";
import StorageService from "../../services/Storage.service";
import Moment from 'moment';
import { getFilesViewDetails } from "../../reducers/viewFileReducer";
import { fetchFilesViewRequest } from "../../actions/viewFile.Actions";
import { fetchDeleteFilesRequest } from "../../actions/deleteFileActions";
import { getDelFilesDetails } from "../../reducers/deleteFileReducer";
import { v4 as uuidv4 } from 'uuid'

const UploadDocumentation = (props: any, { token }: any) => {
  let employerNameCoki = StorageService.getCookies("employerUrl");
  let params = useParams();
  const dispatch = useDispatch();
  const [file, setFile] = useState({});
  const [fileType, setFileType] = useState("");
  const date = Moment().format('MM-DD-YYYY')

  const navigate = useNavigate();

  const [isSubmitted, setSubmitted] = useState(false);

  let step1Res: any = useSelector(getStep1Details);
  const result = step1Res.uploadedDocList;

  useEffect(() => {
    step1Res = null;
    getStep1ByEventId();
  }, []);

  const getStep1ByEventId = () => {
    step1Res = null;
    let eventToken = StorageService.getCookies("eventToken");
    let token: any = params.token ? params.token : eventToken;
    if (token && token !== "0") dispatch(fetchGetStep1Request({ token }));
  };

  const [preview, setPreview] = useState(false);

  const previewFile = (fileItems: any) => {
    debugger;
    setPreview(false);
    forceUpdate();
    const fileExt = fileItems.file.name.split(".").pop().toLowerCase();
    let file = fileItems.file;
    if (fileExt === "doc" || fileExt === "docx") {
      file = URL.createObjectURL(fileItems.file);
    }
    props.set(true);
    setFile(file);
    setFileType(fileItems.file.name.split(".")[1]);
    setPreview(true);
  };

  const closePreview = (fileItems: any) => {
    setPreview(false);
    props.set(true);
  };

  interface fileList {
    fileName: string;
    file: any;
  }
  const [children, setChildren] = useState<fileList[]>([]);
  const [fileName, setFileName] = useState("");
  const [userFile, setUserFile] = useState<any>(null as any);
  const [fileKey, setFileKey] = useState<any>("");
  const [errorMsg, setErrorMsg] = useState("");

  const ref: any = useRef();
  const handleFileChoose = (ev: any) => {
    let file = ev.target.files[0];
    let fileName = file.name.split(".");
    let extention = fileName.pop();
    let name = "";
    for (var i = 0; i < fileName.length; i++) {
      name = name + fileName[i];
    }
    name = name.replace(/[^A-Z0-9]+/ig, '');
    if (name.length > 1) {
      const newFile = new File([file], name + "." + extention);
      setErrorMsg("");
      setUserFile(newFile);
    } else {
      setErrorMsg("Invalid fileName");
    }
  }
  const addChild = () => {
    if (
      userFile &&
      !["pdf", "doc", "docx", "jpg", "jpeg", "png"].includes(
        userFile.name.split(".").pop().toLowerCase()
      )
    ) {
      alert(
        "Please upload only file with below formats - PDF, Image, Doc,Docx"
      );
      return;
    }
    const cloneChildren = [...children];
    cloneChildren.push({
      fileName: fileName,
      file: userFile,
    });
    setChildren(cloneChildren);
    setFileName("");
    ref.current.value = null;
    setFileKey("");
    props.set(true);
    setUserFile(null);

    // if (userFile){
    //   setUserFile(null)
    //   setFileKey(userFile.name);
    //   setFileKey("");
    // }
  };

  const handleDelete = (index: number, fileItems: any) => {
    const cloneChildren = [...children];
    cloneChildren.splice(index, 1);
    setChildren(cloneChildren);
    closePreview(fileItems);
    props.set(true);
  };
  const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void;

  const onError = (error: any) => {
    console.log(error);
  };

  let step3Res: any = useSelector(getSTEP3EnteredDetails);

  // const allStepsCompleted = () => {
  //   debugger
  //   setSubmitted(true);
  //   if (children && children.length > 0) {
  //     const formData = new FormData();
  //     if (step1Res && step1Res.eventId > 0) {
  //       let evtId = step1Res.eventId.toString();
  //       formData.append('eventId', evtId);

  //       for (let i of children) {
  //         formData.append('fileName', i.fileName);
  //         formData.append('file', i.file);
  //       }
  //       dispatch(fetchSTEP3Request({ formData }));
  //       navigate("/form-thankyou");
  //       //checkResult();
  //     }
  //   }
  // }

  const [initialValues, setInitialValues] = useState<any>({});

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values: any, actions) => {
      setSubmitted(true);
      if (children && children.length > 0) {
        const formData = new FormData();
        if (step1Res && step1Res.eventId > 0) {
          let evtId = step1Res.eventId.toString();
          formData.append("eventId", evtId);

          for (let i of children) {
            formData.append("fileName", i.fileName);
            formData.append("file", i.file);
          }
          dispatch(fetchSTEP3Request({ formData }));
        }
      }
    },
  });
  //-------------------preview file------------------------------
  const [open, setOpen] = React.useState(false);
  const [roleId, setRoleId] = React.useState(0);

  const handleClose = () => {
    setOpen(false);
  };
  const [pre, setPre] = useState(false)
  var fileRes = useSelector(getFilesViewDetails);

  useEffect(() => {
    if (fileRes && pre) {
      setPre(false)
      const file = new Blob([fileRes], { type: fileRes.type })

      const fileURL = URL.createObjectURL(file)
      window.open(fileURL, "_blank")
      fileRes = null
    }
  }, [fileRes]);



  const fileView = (file: any) => {
    let id: any = step1Res.eventId.toString();
    let fileName: any = file;

    dispatch(fetchFilesViewRequest({ id, fileName }))
    setPre(true)
  }

  //------------------------------delete file -----------------------

  const handleRemove = (Id: any) => {
    setSubmitted(true);
    let id: any = step1Res.eventId.toString();
    let fileId: any = Id;

    dispatch(fetchDeleteFilesRequest({ id, fileId }));
    handleClose();

  }
  var fileDataAfterDel: any = useSelector(getDelFilesDetails);
  useEffect(() => {
    if (fileDataAfterDel && fileDataAfterDel.isSuccess === true) {
      toast.success(`Deleted Successfully`);
      getStep1ByEventId()
      fileDataAfterDel.isSuccess = null

    }
    else if (fileDataAfterDel && fileDataAfterDel.isSuccess == false) {
      // handleClose();
      toast.error(fileDataAfterDel.errorMessages[0]);
      fileDataAfterDel.isSuccess = true
    }
  }, [fileDataAfterDel]);


  useEffect(() => {
    if (
      step3Res &&
      step3Res.uploadedFileList &&
      step3Res.uploadedFileList.length > 0
    ) {
      toast.success(`Files uploaded successfully`);
      //navigate("/form-thankyou");
      navigate(`/` + employerNameCoki + `/form-thankyou/PendingReview`);
    } else if (step3Res && step3Res.isSuccess == false) {
      let msg = step3Res.errorMessages[0];
      toast.error(msg);
    }
  }, [step3Res]);

  return (
    // <form>
    <form onSubmit={formik.handleSubmit}>
      <Box>
        <table>
          <tr>
            <th className="text-muted">Name:</th>
            <td>{step1Res && step1Res.name}</td>
          </tr>
          <tr>
            <th className="text-muted">Event Type:</th>
            <td>{step1Res && step1Res.eventType}</td>
          </tr>
          <tr>
            <th className="text-muted">Sub-Event Type:</th>
            <td>{step1Res && step1Res.eventSubType}</td>
          </tr>
          <tr>
            <th className="text-muted" style={{ textAlign: "inherit" }}>
              Confirmation Number:
            </th>
            <td>{step1Res && step1Res.confirmationNumber}</td>
          </tr>
        </table>

        <Typography className="para">
          Thanks for initiating your QLE and telling us about the changes you
          want to make to your benefits. Now we need some supporting
          documentation for our records. Remember, to be eligible to make
          changes to your benefits related to your qualifying life event, you
          must submit your documentation within 60 days of the event. Your
          documentation must provide proof of the qualifying event, including
          the date of the event.
        </Typography>

        <Typography
          className="para"
          style={{ position: "relative", bottom: "9px" }}
        >
          Based on your Event Type and the changes you have requested to your
          benefits, please submit any one of the following documents:
        </Typography>
        {step1Res &&
          step1Res.documents.map((item: any, key: any) => (
            <p
              dangerouslySetInnerHTML={{
                __html: item,
              }}
            ></p>
          ))}
        <Typography
          className="para"
          style={{ position: "relative", bottom: "28px" }}
        >
          <p className="para2">
            {/* {step1Res && (
              <p
                dangerouslySetInnerHTML={{
                  __html: step1Res.step3DocumentCovered,
                }}
              ></p>
            )} */}
            {ReactHtmlParser(step1Res && step1Res.step3DocumentCovered)}
          </p>
        </Typography>

        <Typography
          className="para"
          style={{ position: "relative", bottom: "44px" }}
        >
          When you have your documentation ready, use the button below to upload
          it. You may upload multiple documents. If we don't receive supporting
          documentation within 60 days of your event date, we will automatically
          close this qualifying life event submission.
        </Typography>
        <Typography variant="h6" sx={{ margin: "-34px 0px 0px 0px" }}>
          Upload Documentation
        </Typography>
        <div
          className={
            children && children.length == 0 && isSubmitted
              ? "error-span doc"
              : "error-span"
          }
        >
          {children && children.length == 0 && isSubmitted && isSubmitted
            ? "Please add atleast one file"
            : ""}
        </div>

        <Grid container sx={{ marginTop: "20px" }}>
          <Grid item xs={12} md={4}>
            <TextField
              id="fileName"
              label="File Name (*)"
              variant="outlined"
              name="Name1"
              fullWidth
              value={fileName}
              onChange={(ev) => {
                setFileName(ev.target.value);
                if (ev.target.value) {
                  props.set(true);
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={5} className="choose_file">
            <TextField
              id="supportingDoc"
              variant="outlined"
              sx={{ display: "block" }}
              inputProps={{ accept: ".pdf, .doc, .docx, .jpg , .jpeg , .png" }}
              name="file"
              type="file"
              placeholder=""
              className="align-upload"
              fullWidth
              inputRef={ref}
              //key={fileKey}
              // onChange={(ev: any) => {
              //   if (ev.target.files[0].name.split('.').length > 2) {
              //     setErrorMsg("The file name should not include '.' in it.");
              //     return false;
              //   }
              //   setUserFile(ev.target.files[0]);
              //   setErrorMsg("");
              // }}
              onChange={(ev: any) => {
                handleFileChoose(ev);
                props.set(true);
              }}
            />
            <InputLabel
              htmlFor="outlined-adornment-amount"
              style={{
                fontSize: "0.95rem",
                display: "block",
                whiteSpace: "normal",
              }}
            >
              Your file name cannot have any spaces and should include only the
              following – letters, numbers, dashes (-) and underscores (_)
            </InputLabel>
            {errorMsg && (
              <span style={{ color: "red", fontSize: "12px" }}>{errorMsg}</span>
            )}
          </Grid>

          <Grid item xs={12} md={2} className="newbtn1">
            <Button
              style={{ marginLeft: "23px" }}
              fullWidth
              className="align-upload"
              sx={{
                color: "#6c757d",
                marginLeft: "20px",
                border: "1px solid gray",
                padding: "13px",
                "&:hover": {
                  background: "#6c757d",
                  color: "#ffff",
                },
              }}
              disabled={!fileName || !userFile}
              onClick={addChild}
            >
              <AddIcon />
              <Typography sx={{ padding: "5px 0px 0px 6px", fontSize: "16px" }}>
                ADD
              </Typography>
            </Button>
            <InputLabel
              htmlFor="outlined-adornment-amount"
              style={{
                fontSize: "0.95rem",
                marginLeft: "25px",
                whiteSpace: "normal",
              }}
            >
              Select +ADD to upload your document
            </InputLabel>
          </Grid>
        </Grid>

        {/* ------------uploaded files list - start-------------- */}

        <Grid container md={7} sx={{ margin: "20px 2px 0px 2px" }} className="header_width" >
          <Grid item md={1} className="bx-1" xs={1}></Grid>
          <Grid item md={5} className="bx-1" xs={6}>
            <Typography sx={{ fontWeight: "600" }}>File Name</Typography>
          </Grid>

          <Grid item md={2} className="bx-1" xs={5}>
            <Typography sx={{ fontWeight: "600" }}>Preview</Typography>
          </Grid>

          <Grid item md={4} className="bx-1" xs={1}>
            <Typography sx={{ fontWeight: "600", marginLeft: "45px" }}>Date </Typography>
          </Grid>
        </Grid>

        {result && result.map((item: any, index: number) => {
          return (
            <>
              <Grid container md={7} sx={{ margin: "2px 2px 0px 2px" }} className="files-width">
                <Grid item md={1} className="bx-1" xs={1}>

                  <PictureAsPdf />

                </Grid>
                <Grid item md={5} className="bx-1" xs={4} id="fileName">
                  <Typography sx={{ padding: "1px 0px" }}>

                    {item.documentName}
                  </Typography>
                </Grid>

                <Grid item md={2} className="bx-1" xs={4}>
                  <Typography
                    className="text-red"
                    sx={{ padding: "1px 0px" }}
                    onClick={() => {

                      fileView(item.fileName)
                    }}
                  >
                    <Preview />
                  </Typography>
                </Grid>
                <Grid item md={3} className="bx-1" xs={4}>
                  <Typography
                    className=""
                    sx={{ padding: "1px 0px" }}
                  >
                    {item.createdDate}
                  </Typography>

                </Grid>
                <Grid md={1} mt={1}>
                  <TableCell>
                    <Button
                      variant="text"
                      sx={{ border: "none", width: "20px", padding: "0px", marginTop: "-5px" }}
                      key={`${result.id}.8`}
                      onClick={() => {
                        setRoleId(item.id);
                        setOpen(true);
                      }}
                    // disabled={isDisabled}
                    >

                      <DeleteForeverSharpIcon style={{ marginTop: "-15px" }} color="primary" onClick={() => {
                        setRoleId(item.id)
                      }} />
                    </Button>
                    <Dialog
                      open={open}
                      keepMounted
                      onClose={handleClose}
                      aria-describedby="alert-dialog-slide-description"
                      sx={{
                        top: "-450px",

                      }}
                      fullWidth

                      key={`${result.id}.9`}
                    >

                      <DialogTitle >
                        <Typography
                          variant="caption"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="h5" sx={{ fontSize: "18px", fontWeight: "600" }}>
                            {"Confirmation"}
                          </Typography>

                          <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                              position: 'absolute',
                              right: 8,
                              top: 8,
                              color: (theme) => theme.palette.grey[500],
                            }}
                          >
                            <CloseIcon />
                          </IconButton>

                        </Typography>
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText sx={{ fontSize: "17px" }} id="alert-dialog-slide-description">
                          Sure to Delete?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions sx={{ padding: "12px 19px" }}>
                        <Button className="cancel-btn-role" onClick={handleClose}>No</Button>
                        <Button className="save-btn-role" onClick={() => handleRemove(roleId)}>Yes</Button>

                      </DialogActions>
                    </Dialog>
                  </TableCell>


                </Grid>

                {/* <Grid item md={1} className="bx-1" xs={1} >
                    <TableCell>
                      <Button
                        variant="text"
                        sx={{ border: "none", width: "20px", padding: "0px", marginTop: "-5px" }}
                        key={`${res.id}.8`}
                        onClick={() => {
                          setRoleId(item.id);
                          setOpen(true);
                        }}
                        disabled={isDisabled}
                      >
                       {isPrivilegedUser &&
                        <DeleteForeverSharpIcon color="primary" onClick={()=>{ 
                          setRoleId(item.id)}} />}
                      </Button>
                      <Dialog
                        open={open}
                        keepMounted
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                        sx={{
                          top: "-450px",

                        }}
                        fullWidth

                        key={`${res.id}.9`}
                      >

                        <DialogTitle >
                          <Typography
                            variant="caption"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="h5" sx={{ fontSize: "18px", fontWeight: "600" }}>
                              {"Confirmation"}
                            </Typography>

                            <IconButton
                              aria-label="close"
                              onClick={handleClose}
                              sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                              }}
                            >
                              <CloseIcon />
                            </IconButton>

                          </Typography>
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText sx={{ fontSize: "17px" }} id="alert-dialog-slide-description">
                            Sure to Delete?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ padding: "12px 19px" }}>
                          <Button className="cancel-btn-role" onClick={handleClose}>No</Button>
                          <Button className="save-btn-role" onClick={() => handleRemove(roleId)}>Yes</Button>

                        </DialogActions>
                      </Dialog>
                    </TableCell>
                  </Grid> */}

              </Grid>
            </>
          )
        }
        )}

        {children.map((fileItem: any, index: number) => (
          <Grid container md={7} sx={{ margin: "2px 2px 0px 2px" }}>
            <Grid item md={1} className="bx-1" xs={1}>
              {fileItem.file.type.indexOf('image') >= 0 ? <Image /> :
                fileItem.file.type.indexOf('doc') >= 0 ? <ArticleIcon /> : <PictureAsPdf />}
            </Grid>
            <Grid item md={5} className="bx-1" xs={4}>
              <Typography sx={{ padding: "1px 0px" }}>
                {fileItem.fileName}
              </Typography>
            </Grid>

            <Grid item md={2} className="bx-1" xs={4}>
              <Typography
                className="text-red"
                sx={{ padding: "1px 0px" }}
                onClick={() => {
                  previewFile(fileItem);
                }}
              >
                <Preview />
              </Typography>
            </Grid>
            <Grid item md={3} className="bx-1" xs={4}>
              <Typography
                className=""
                sx={{ padding: "1px 0px" }}

              >
                {date}
              </Typography>
            </Grid>

            <Grid item md={1} className="bx-1" xs={1}>
              <DeleteForeverSharpIcon
                onClick={() => {
                  handleDelete(index, fileItem);
                }}
                sx={{ color: "red", cursor: "pointer" }}
              />
            </Grid>
          </Grid>
        ))}

        {/*--------------- uploaded files list - end ---------------*/}

        {/* <input type='file' onChange={fileUploaded} /> */}
        {preview && (
          <Button
            className="align-upload"
            sx={{
              marginTop: "20px",
              color: "#6c757d",
              "&:hover": {
                background: "#cccccc",
                color: "#555555",
              },
            }}
            onClick={closePreview}
          >
            <Typography sx={{ padding: "5px 0px 0px 0px", fontSize: "12px" }}>
              Close Preview
            </Typography>
          </Button>
        )}
        {preview ? (
          <div
            style={{
              height: "800px",
              width:
                fileType === "pdf" || fileType === "doc" || fileType === "docx"
                  ? "100%"
                  : "424px",
              marginTop: "20px",
            }}
          >
            {fileType === "doc" || fileType === "docx" ? (
              <FileViewer
                fileType={fileType}
                filePath={file}
                onError={onError}
              />
            ) : (
              <FilePreview
                width={
                  fileType === "pdf" ||
                    fileType === "doc" ||
                    fileType === "docx"
                    ? "100%"
                    : "424px"
                }
                type={"file"}
                file={file}
                onError={onError}
              />
            )}

            {/* {preview &&
              <DocViewer documents={docs} pluginRenderers={DocViewerRenderers}/>
            } */}
          </div>
        ) : (
          ""
        )}

        <Typography className="para">
          <span className="text-red">IMPORTANT NOTE:</span>
          {step1Res && (
            <p
              dangerouslySetInnerHTML={{
                __html: step1Res.step3ImportantNote,
              }}
            ></p>
          )}
          {/* <Link
            className="link-styling"
            to="https://staffing.benefitsnow.com"
          >
            https://staffing.benefitsnow.com
          </Link>{" "} */}
          {/* and click on the link for Dependent Verification Information. If you
          do not submit the appropriate dependent verification documentation
          in a timely manner, your spouse, domestic partner and/or dependent
          children may be removed from Randstad coverage. */}
        </Typography>

        <hr />
      </Box>

      <Button
        type="submit"
        // onClick={allStepsCompleted}
        sx={{
          backgroundColor: "red",
          "&:hover": {
            backgroundColor: "orange",
          },
        }}
        variant="contained"
        className="flex-end btn-bg-red"
      >
        Submit
      </Button>
    </form>
  );
};

export default UploadDocumentation;
