import { getRecaptchaTypes } from "../constants/actionTypes";
import {
    FetchGetRecaptchaFailure,
    FetchPostsFailurePayload,
    FetchGetRecaptchaRequest,
    FetchGetRecaptchaSuccess
} from "../interfaces/types";

export const fetchGetRecaptchaRequest = (payload: any): FetchGetRecaptchaRequest => ({
    type: getRecaptchaTypes.FETCH_GET_RECAPTCHA_REQUEST,
    payload
});

export const fetchGetRecaptchaSuccess = (
    payload: any
): FetchGetRecaptchaSuccess => ({
    type: getRecaptchaTypes.FETCH_GET_RECAPTCHA_SUCCESS,
    payload
});

export const fetchGetRecaptchaFailure = (
    payload: FetchPostsFailurePayload
): FetchGetRecaptchaFailure => ({
    type: getRecaptchaTypes.FETCH_GET_RECAPTCHA_FAILURE,
    payload
});


