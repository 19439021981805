import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    fetchGetRecaptchaFailure,
    fetchGetRecaptchaSuccess
} from "../actions/getRecaptchaActions";
import { getRecaptchaTypes } from '../constants/actionTypes';
import { getRecaptcha } from "../services/Recaptcha";

function* fetchGetRecaptchaSaga({ payload }: any): any {
    try {
       
        const response = yield call(getRecaptcha, payload);
        console.log(response)
        yield put(
            fetchGetRecaptchaSuccess(
                response.data
            )
        );
    } catch (e: any) {
        yield put(
            fetchGetRecaptchaFailure({
                error: 'Unauthorized',
               
            })
        );
    }
}

function* getRecaptchaSaga() {
    yield all([takeLatest(getRecaptchaTypes.FETCH_GET_RECAPTCHA_REQUEST, fetchGetRecaptchaSaga)]);
}

export default getRecaptchaSaga;