import axios from "axios";
import { type } from "os";
import { BASE_URL } from "../constants/actionTypes";
import StorageService from "./Storage.service";
const token = StorageService.getCookies("accessToken");

type STEP3State ={
  "fileName": "",
  "Name":"",
  "eventId": 0,
}
export const getViewDeatails = (payload: any): any => {
  const token = StorageService.getCookies("accessToken");
  try {
    return axios({
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      url:`${BASE_URL}/qleform/view/${payload.id}/${payload.fileName}`,
              data: {
                  fileName:payload.fileName,
                  // file:payload.file,
                  eventId:payload.eventId
                
              },
      responseType:'blob'

    });
  } catch (error) {
    throw error;
  }
};
//deletefiles
export const getDeleteDeatails = (payload: any): any => {
  const token = StorageService.getCookies("accessToken");
  try{ console.log("deletefile");console.log(payload)
    return axios({
      method: "delete",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      url: BASE_URL + `/qleform/deletefile/${payload.id}/${payload.fileId}`,
      data:JSON.stringify(payload)
      
    });
  } catch (error) {
    throw error;
  }
}