import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    fetchGetUploadLogoFailure,
    fetchGetUploadLogoSuccess
} from "../actions/getEmployerLogo";
import { getUploadLogoTypes } from '../constants/actionTypes';
import { getUserLogo } from "../services/employerLogo";

function* fetchGetUploadLogoSaga({ payload }: any): any {
    try {
       
        const response = yield call(getUserLogo, payload);
        console.log(response)
        yield put(
            fetchGetUploadLogoSuccess(
                response.data
            )
        );
    } catch (e: any) {
        yield put(
            fetchGetUploadLogoFailure({
                error: 'Unauthorized',
               
            })
        );
    }
}

function* getUploadLogoSaga() {
    yield all([takeLatest(getUploadLogoTypes.FETCH_GET_UPLOADLOGO_REQUEST, fetchGetUploadLogoSaga)]);
}

export default getUploadLogoSaga;