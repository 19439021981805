import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import StorageService from "../../services/Storage.service";
import { useDispatch, useSelector } from "react-redux";
import { getUploadLogoDetails } from "../../reducers/getEmployerLogoReducer";
import { useEffect, useState } from "react";
import { fetchGetUploadLogoRequest } from "../../actions/getEmployerLogo";
import { getEmployerDetailsBySlug } from "../../reducers/slugReducer";
import { fetchSlugRequest } from "../../actions/slugActions";

const useStyles=makeStyles({
  root: {
    "& .MuiPaper-root": {width :"100% !important", left: "0px", color:"#fff", backgroundColor:"#0f1941"},
    
  }

})
const Header = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const params = useParams()
  const dispatch = useDispatch()



  let url = window.location.href;

  const employerRes = useSelector(getEmployerDetailsBySlug);
  useEffect(() => {
    getEmployer();
  }, []);

  const getEmployer = () => {
    let employerTest = url.toString();
    let pathName = window.location.pathname; //This will give /randstad
    let idx = pathName.indexOf("/");
    let employerName = pathName.substring(idx+1);
    if(employerName && employerName.length > 0){
      // alert("url given");
    }else{
      // alert("url NOT given");
      employerName="Randstad"
    }
    // alert(employerName);
    if(employerName.indexOf("/") != -1){
      StorageService.setCookies('employerUrl', employerName.substring(0,employerName.indexOf("/")));
    }else{
      StorageService.setCookies('employerUrl', employerName);
    }
    getEmployerLogo()

  };
const getEmployerLogo = () => {
  let eId = StorageService.getCookies("employerId");
  if (eId > 0) dispatch(fetchGetUploadLogoRequest({}));
}
  useEffect(() => {
    if(StorageService.getCookies('empName')){
      document.title = StorageService.getCookies('empName');
    }
    if (employerRes && employerRes.response) {
      if (employerRes.response.id > 0){
        StorageService.setCookies('employerId', employerRes.response.id);
        StorageService.setCookies('employerName', employerRes.response.name.replace(/[^a-zA-Z]/g,""));
        // StorageService.setCookies('employerUrl', employerRes.response.url);
        StorageService.setCookies('empName', employerRes.response.name);
        document.title = employerRes.response.name;
      }
      getEmployerLogo()
    }
  }, [employerRes]);



  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };
  

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navigate = useNavigate();
  const location = useLocation();
  
  const classses=useStyles();
  let employerUrl = StorageService.getCookies("employerUrl");
  const pages = [
    { page: "Home", url: "/"+employerUrl+"/" },
    { page: "FAQ", url: "/"+employerUrl+"/faq" },
    { page: "Contact", url: "/"+employerUrl+"/contact-us" },
  ];
  //---------------------get upload image ---------------------

  const getUploadImageRes: any = useSelector(getUploadLogoDetails);
  var res = getUploadImageRes.data;

  const [image, setImage] = useState("");

  useEffect(() => {
    if (res) {
      const file = new Blob([res], { type: res.type });
      var fileURL = URL.createObjectURL(file);
      setImage(fileURL);
      getUploadImageRes.data = null;
    }
  }, [res]);


  return (
    <AppBar position="sticky" className="navbar-qle">
      <Toolbar disableGutters style={{ padding: "0px 25px" }}>
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
          onClick={() => navigate("/"+StorageService.getCookies('employerUrl')+"/")}
          style={{cursor: "pointer"}}
        >
          <img src={image} height="25"/>
        </Typography>
        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none", justifyContent: "end" } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
           
          >
            <MenuIcon />
          </IconButton>
          <Menu
           className={classses.root}
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            {pages.map((page, index) => (
              <MenuItem key={index} onClick={() => navigate(page.url)}>
                <Typography  textAlign="center">{page.page}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "none", md: "flex" },
            justifyContent: "end",
          }}
        >
          {pages.map((page, index) => (
            <Button
              key={index}
              onClick={() => navigate(page.url)}
              sx={{ my: 2, color: "rgba(255,255,255,.5)", display: "block", fontWeight:"400"}}
              className={location.pathname == page.url ? "active" : "p-15" }
            >
              {page.page}
            </Button>
          ))}
        </Box>
      </Toolbar>
      {/* </Container> */}
    </AppBar>
  );
};
export default Header;
