import { GetUploadLogoActions, GetUploadLogoState} from "../interfaces/types";
import {getUploadLogoTypes} from "../constants/actionTypes";
import {RootState} from "./index";

const initialState: GetUploadLogoState = {
    pending: false,
    error: null, 
};

export default (state = initialState, action: GetUploadLogoActions) => {
    

    switch (action.type) {
        case getUploadLogoTypes.FETCH_GET_UPLOADLOGO_REQUEST:
            return {
                ...state,
                pending: true
            };
        case getUploadLogoTypes.FETCH_GET_UPLOADLOGO_SUCCESS:
            return {
                ...state,
                pending: false,
                ...action.payload,
                data: action.payload,
                error: null,
            };
        case getUploadLogoTypes.FETCH_GET_UPLOADLOGO_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.payload.error
            };
        default:
            return state;
    }
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getUploadLogoDetails = (state: RootState) => state.getUploadLogo;