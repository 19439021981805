import { getUploadLogoTypes } from "../constants/actionTypes";
import {
    FetchGetUploadLogoFailure,
    FetchPostsFailurePayload,
    FetchGetUploadLogoRequest,
    FetchGetUploadLogoSuccess
} from "../interfaces/types";

export const fetchGetUploadLogoRequest = (payload: any): FetchGetUploadLogoRequest => ({
    type: getUploadLogoTypes.FETCH_GET_UPLOADLOGO_REQUEST,
    payload
});

export const fetchGetUploadLogoSuccess = (
    payload: any
): FetchGetUploadLogoSuccess => ({
    type: getUploadLogoTypes.FETCH_GET_UPLOADLOGO_SUCCESS,
    payload
});

export const fetchGetUploadLogoFailure = (
    payload: FetchPostsFailurePayload
): FetchGetUploadLogoFailure => ({
    type: getUploadLogoTypes.FETCH_GET_UPLOADLOGO_FAILURE,
    payload
});


