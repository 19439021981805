import axios from "axios";
import { BASE_URL} from "../constants/actionTypes";
import StorageService from "./Storage.service";
const token = StorageService.getCookies("accessToken");




//get UploadImage

export const getRecaptcha = (payload: any): any => {
    // const employerId = StorageService.getCookies("employerId");
   const employerId = StorageService.getCookies('employerId');
    try {
      return axios({
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
         
        },
        url: `${BASE_URL}/auth/recaptcha`,
        // http://172.30.1.4:8180/qleaca/api/auth/recaptcha
        // responseType:'blob'
      });
    } catch (error) {
      throw error;
    }
  };
  